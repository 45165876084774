import {useGetCarePodSchedulingQuery} from "@store";
import {Box, Text} from "ferns-ui";
import React from "react";

import {DataExplorer} from "./DataExplorer";
import {useExplorerContext} from "./UserExplorerContext";

export const CarePodSchedulingTable = (): React.ReactElement => {
  const {carePodId, dateFilter} = useExplorerContext();

  if (!carePodId) {
    return (
      <Box alignItems="center" flex="grow" paddingY={12}>
        <Text bold>Select a care pod to see scheduling data</Text>
      </Box>
    );
  }

  return (
    <DataExplorer
      alternateRowBackground={false}
      defaultTextSize="sm"
      filters={{
        carePodId,
        dateFilter,
      }}
      hook={useGetCarePodSchedulingQuery}
      noDataMessage="No therapist availability slots. Set availability in My Team."
      page={1}
      pinnedColumns={2}
      rowHeight={130}
    />
  );
};
