import {FormInstance, usePatchFormInstanceAnswerMutation, useSentryAndToast} from "@store";
import {Mutex} from "async-mutex";
import isEqual from "lodash/isEqual";
import {useCallback, useMemo, useRef} from "react";

type UseFormInstancePatchReturnType = {
  patchAnswer: (answer: FormInstance["answers"][number]) => Promise<void>;
};
// a single mutex for the entire form instance
export const useFormInstancePatch = (
  formInstanceId: string,
  readOnly: boolean
): UseFormInstancePatchReturnType => {
  const sentryAndToast = useSentryAndToast();
  const [patchFormInstanceAnswer] = usePatchFormInstanceAnswerMutation();
  const formInstanceMutex = useMemo(() => new Mutex(), []);
  // cue of Pending patches
  const patchQueue = useRef<Array<{answerId: string; answer: FormInstance["answers"][number]}>>([]);

  const processQueue = useCallback(async () => {
    if (patchQueue.current.length === 0) return;

    await formInstanceMutex.runExclusive(async () => {
      while (patchQueue.current.length > 0) {
        const {answerId, answer} = patchQueue.current.shift()!; // get first item in queue

        await patchFormInstanceAnswer({formInstanceId, answerId, answer})
          .unwrap()
          .catch((error) => {
            sentryAndToast(
              `Error saving update to form. Please try again before continuing.`,
              error as Error
            );
            // Re-throw the error so the caller can handle it and not clear the local
            // answer
            throw error;
          });
      }
    });
  }, [formInstanceMutex, patchFormInstanceAnswer, formInstanceId, sentryAndToast]);

  // Add patch request to queue and process in order
  const patchAnswer = useCallback(
    async (answer: FormInstance["answers"][number]) => {
      if (readOnly || !answer) return;

      // add to queue (prevent redundant patches)
      // remove only if the answerId matches AND the answers field is unchanged
      patchQueue.current = patchQueue.current.filter(
        (item) => !(item.answerId === answer._id! && isEqual(item.answer.answers, answer.answers))
      );
      patchQueue.current.push({answerId: answer._id!, answer});

      void processQueue();
    },
    [readOnly, processQueue]
  );

  return {patchAnswer};
};
