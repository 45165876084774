import {ButtonProps} from "ferns-ui";

import {
  addTagTypes,
  GetAlertInstancesByIdRes,
  GetAlertsByIdRes,
  GetAppointmentSlotsRes,
  GetAuditLogByIdRes,
  GetAvatarsByIdRes,
  GetCarePodsByIdRes,
  GetCompanyOrganizationsRes,
  GetConversationsByIdRes,
  GetDotPhrasesByIdRes,
  GetExternalCliniciansByIdRes,
  GetFamilyUnitsByIdRes,
  GetFitbitHrvByIdRes,
  GetFitbitSleepByIdRes,
  GetFitbitStatusesByIdRes,
  GetFitbitStepsByIdRes,
  GetFormInstancesByIdRes,
  GetFormsByIdRes,
  GetGuidingHoursByIdRes,
  GetInsurancePlansByIdRes,
  GetMessagesByIdRes,
  GetPreClinicUpdatesByIdRes,
  GetPtoCoverageConfigsByIdRes,
  GetRecurringScheduleItemsByIdRes,
  GetReferralMethodsByIdRes,
  GetReferralSourcesByIdRes,
  GetScheduleItemsByIdRes,
  GetTodoByIdRes,
  GetUserBioByIdRes,
  GetUsersByIdRes,
  GetUserSessionsByIdRes,
  GetUserSettingsByIdRes,
  GetUserStatusesByIdRes,
  GetUserUpdatesByIdRes,
  GetVoicemailsByIdRes,
  GetWorkflowMappingsByIdRes,
  GetWorkflowMappingsRes,
  LimitedUser,
} from "./openApiSdk";

// Constants
export const clinicalUpdateFieldOptions: ClinicalInfoSelectOptions = {
  clinicalStatus: [
    {label: "🔴 High risk / complex / severe symptoms", value: "High", score: 0},
    {label: "🟡 Moderate risk / stable", value: "Moderate", score: 0},
    {label: "🟢 Mild-Moderate risk / thriving", value: "Mild-Moderate", score: 0},
  ],
  therapyCadence: [
    {label: "Weekly", value: "Weekly"},
    {label: "Every other week", value: "EveryOtherWeek"},
    {label: "Twice weekly", value: "TwiceWeekly"},
  ],
};

export const userSelectFieldOptions: UserSelectFieldOptions = {
  patientTreatmentManualModule: [
    {label: "1.01: Orientation", value: "1.01: Orientation"},
    {label: "1.02: Coping & Distress Tolerance", value: "1.02: Coping & Distress Tolerance"},
    {label: "1.03: Values & Goals", value: "1.03: Values & Goals"},
    {label: "1.04: Personalized Plan", value: "1.04: Personalized Plan"},
    {label: "1.05: Motivation vs Momentum", value: "1.05: Motivation vs Momentum"},
    {label: "1.06: Emotional Disorders", value: "1.06: Emotional Disorders"},
    {label: "1.07: Thoughts & Behaviors", value: "1.07: Thoughts & Behaviors"},
    {
      label: "1.08: Emotional Avoidance vs Approaching Emotions",
      value: "1.08: Emotional Avoidance vs Approaching Emotions",
    },
    {label: "1.09: Thought Defusion", value: "1.09: Thought Defusion"},
    {label: "1.10: Opposite Action", value: "1.10: Opposite Action"},
    {label: "1.11: Behavior Activation", value: "1.11: Behavior Activation"},
    {label: "1.12: Communication", value: "1.12: Communication"},
    {label: "2.01: Sleep & Health", value: "2.01: Sleep & Health"},
    {label: "2.02: Nutrition", value: "2.02: Nutrition"},
    {label: "2.03: Physical Fitness", value: "2.03: Physical Fitness"},
    {label: "2.04: Sexual Health", value: "2.04: Sexual Health"},
    {label: "2.05: Online Safety", value: "2.05: Online Safety"},
    {label: "2.06: Mindfulness / Pause", value: "2.06: Mindfulness / Pause"},
    {label: "2.07: Thought Defusion 2.0", value: "2.07: Thought Defusion 2.0"},
    {label: "2.08: Interpersonal Effectiveness", value: "2.08: Interpersonal Effectiveness"},
    {label: "2.09: Approaching Behaviors", value: "2.09: Approaching Behaviors"},
    {label: "2.10: Problem Solving Skills", value: "2.10: Problem Solving Skills"},
    {label: "2.11: Health-Promoting Routine", value: "2.11: Health-Promoting Routine"},
    {label: "2.12: Plan & Maintenance", value: "2.12: Plan & Maintenance"},
    {label: "3.01: Self-Injurious Behavior", value: "3.01: Self-Injurious Behavior"},
    {
      label: "3.02: Acute Violence / Anger Management",
      value: "3.02: Acute Violence / Anger Management",
    },
    {
      label: "3.03: Foster Care / Changing Home or Caregivers",
      value: "3.03: Foster Care / Changing Home or Caregivers",
    },
    {label: "3.04: Relationships", value: "3.04: Relationships"},
    {label: "3.05: Trauma", value: "3.05: Trauma"},
    {label: "3.06: Substance Use", value: "3.06: Substance Use"},
    {label: "3.07: Act Matrix", value: "3.07: Act Matrix"},
    {label: "3.08: Gender Dysphoria", value: "3.08: Gender Dysphoria"},
    {label: "3.09: Thinking Styles", value: "3.09: Thinking Styles"},
    {label: "3.10: Grief", value: "3.10: Grief"},
    {label: "4.01: Relapse Prevention", value: "4.01: Relapse Prevention"},
    {
      label: "4.02: Discharge Planning & Care Coordination",
      value: "4.02: Discharge Planning & Care Coordination",
    },
    {
      label: "T.1: Pre-Contemplation Stage of Change",
      value: "T.1: Pre-Contemplation Stage of Change",
    },
    {label: "T.2: Acute Distress", value: "T.2: Acute Distress"},
    {
      label: "T.3: Escalated Parents / Families / Caregivers",
      value: "T.3: Escalated Parents / Families / Caregivers",
    },
    {
      label: "T.4: Problem Solving (Food, Shelter, Safety)",
      value: "T.4: Problem Solving (Food, Shelter, Safety)",
    },
    {label: "T.5: Suicide Post Attempt", value: "T.5: Suicide Post Attempt"},
  ],
};

export const ContactTypes = [
  "Emergency Contact",
  "Primary Care Doctor",
  "Dentist",
  "Community Service Board Case Worker",
];

// Copied from /backend. To update edit api/users first. Only include top level properties,
// do not nest props, "address.city" should be "address"
export const patientAllowProps = [
  "_id",
  "address",
  "bio",
  "carePlan",
  "careTeam",
  "consentFormAgreements",
  "email",
  "fitbitConfig",
  "gender",
  "genderSelfDescribe",
  "name",
  "namePronunciation",
  "online",
  "phoneNumber",
  "pronouns",
  "pushNotifications",
  "safetyPlan",
  "settings",
  "smsEnabled",
  "smsMessaging",
  "smsNotifications",
  "staffRoles",
  "timezone",
  "type",
  "usageData",
] as const;

export const SingleCheckboxOptions = {
  checked: "Checked - Yes/True",
  unchecked: "Unchecked - No/False",
};

// Enums

// see permissions https://www.notion.so/flourishhealth/Staff-Roles-Permissions-103840fb4a5e80419e9fe1b688c08e02?pvs=4
export enum StaffRoles {
  ClinicalDirector = "ClinicalDirector",
  ClinicalLeader = "ClinicalLeader",
  ClinicalQualityAssurance = "ClinicalQualityAssurance",
  EnrollmentCoordinator = "EnrollmentCoordinator",
  EnrollmentSupervisor = "EnrollmentSupervisor",
  FamilyGuide = "FamilyGuide",
  FamilyGuideSupervisor = "FamilyGuideSupervisor",
  MedicalDirector = "MedicalDirector",
  PatientGuide = "PatientGuide",
  PatientGuideSupervisor = "PatientGuideSupervisor",
  Psychiatrist = "Psychiatrist",
  RiskManager = "RiskManager",
  SoftwareEngineer = "SoftwareEngineer",
  SuperUser = "SuperUser",
  Therapist = "Therapist",
  TherapistSupervisor = "TherapistSupervisor",
}
// const must be used after enum to avoid TS error
export const SupervisorRoles: StaffRoles[] = [
  StaffRoles.ClinicalQualityAssurance,
  StaffRoles.EnrollmentSupervisor,
  StaffRoles.FamilyGuideSupervisor,
  StaffRoles.MedicalDirector,
  StaffRoles.PatientGuideSupervisor,
  StaffRoles.TherapistSupervisor,
];

// Interfaces
export interface AppState {
  // The user ID for the currently selected workflow
  workflowStaffId?: string;
  // The selected WorkflowMapping._id, representing a user in a workflow.
  workflowMappingId?: string;
  showConsentScreen?: boolean;
  lastBiometricsSuccess?: string;
  unreadUserUpdates?: boolean;
  userSettings?: UserSettings;
  collapseAllRightBar: boolean;
  infoModalDataKey: string;
  showSideDrawer: boolean;
  sideDrawerComponentName?: "AlertsView" | "UserUpdatesView" | "VoicemailsView" | undefined;
  internalChatConversationId?: string;
  internalChatIsFocused?: boolean;
}

export interface ConversationParticipant {
  _id?: string;
  userId?: User;
  role?: string;
}

export interface ConversationSplitPageItem {
  item: {
    avatar?: Avatar;
    conversation: Conversation;
    webPresence: {showOnlineWeb: boolean; showOnlineMobile: boolean; doNotDisturb: boolean};
    unreadCount?: number;
  };
  id: string;
}

export interface ConversationUser {
  // Can be undefined for system messages
  userId?: User;
  role?:
    | StaffRoles.PatientGuide
    | StaffRoles.Therapist
    | StaffRoles.Psychiatrist
    | StaffRoles.SuperUser
    | StaffRoles.FamilyGuide;
}

export interface CreateUserBio extends Omit<UpdateUserBio, "_id"> {}

export interface DownloadFileButtonProps extends Omit<ButtonProps, "onClick"> {
  url: string;
  filename: string;
}

export interface FetchPopulatedByIdRes<T> {
  data?: T;
  isSuccess: boolean;
  error?: any;
  isError: boolean;
  isLoading: boolean;
  isFetching: boolean;
}

export interface FetchPopulatedRes<T> {
  data: {data: T[]; more?: boolean; page?: number; limit?: number; total?: number};
  isSuccess: boolean;
  error?: any;
  isError: boolean;
  isLoading: boolean;
  isFetching: boolean;
}

export interface FormInstanceAnswer {
  prompt: string;
  questionId: string;
  answers: string[];
  score?: number;
  grouping?: string;
  _id: string;
  updated?: Date;
  created: Date;
  deleted?: boolean;
}

export interface NotificationItem {
  _id?: string;
  minutesBefore: number;
  sendAsSms: boolean;
  sendAsPush: boolean;
  taskName?: string;
  sent?: boolean;
  sentAt?: Date;
  notifications?: string[];
}

export interface PopulateFields {
  schema: string;
  key: string;
}

export interface RecurringScheduleItemConfig {
  startDatetime: string;
  endDatetime: string;
  interval?: number;
  daysOfWeek?: string[];
  dayOfMonth?: number;
  durationMinutes?: number;
  skippedDates?: string[];
}

export interface ScheduleItemAttendee {
  _id?: string;
  userId: LimitedUser;
  role?: string;
}

export interface StaffHomeScreenViewProps {
  userId?: string;
}

export interface UpdateFamilyUnit extends Omit<FamilyUnit, "createdBy" | "created"> {}
export interface CreateFamilyUnit extends Omit<UpdateFamilyUnit, "_id"> {}

export interface UpdateUserBio extends Omit<UserBio, "created"> {}

// Types

export type ActivityLogEvent = AuditLog;

export type Address = User["address"];

export type Alert = GetAlertsByIdRes;

export type AlertInstance = GetAlertInstancesByIdRes;

export type AppointmentSlot = GetAppointmentSlotsRes["slots"][0][0];

export type AuditLog = GetAuditLogByIdRes;

export type Avatar = GetAvatarsByIdRes;

export type BillingInfo = User["billingInfo"];

export type CarePod = GetCarePodsByIdRes;

export type CharmKey =
  | "chiefComplaints"
  | "symptoms"
  | "physicalExamination"
  | "treatmentNotes"
  | "psychotherapyNotes"
  | "assessmentNotes"
  | "presentIllnessHistory"
  | "familySocialHistory"
  | "reviewOfSystems"
  | "pastMedicalHistory";

export type ClinicalInfoSelectOptions = {
  clinicalStatus: {
    label: string;
    value: User["clinicalStatus"]["status"];
    score?: number;
  }[];
  therapyCadence: {
    label: string;
    value: User["therapyCadence"]["status"];
  }[];
};

export type UserSelectFieldOptions = {
  patientTreatmentManualModule: {
    label: string;
    value: User["patientTreatmentManualModule"];
  }[];
};

export type CompanyOrganizations = GetCompanyOrganizationsRes;

export type ConsentFormAgreement = User["consentFormAgreements"][0];

export type ConsentFormType = User["consentFormAgreements"][0]["consentFormType"];

export type Contact = User["contacts"][0];

export type Conversation = GetConversationsByIdRes;

export type ConversationMessage = GetMessagesByIdRes;

export type DotPhrase = GetDotPhrasesByIdRes;

export type ExternalClinician = GetExternalCliniciansByIdRes;

export type FamilyUnit = GetFamilyUnitsByIdRes;

export type FamilyUnitRelationship = {
  familyUserId: string;
  relationship: string;
};

export type FitbitActivity = GetFitbitStepsByIdRes;

export type FitbitHrv = GetFitbitHrvByIdRes;

export type FitbitSleep = GetFitbitSleepByIdRes;

export type FitbitSteps = GetFitbitStepsByIdRes;

export type FitbitStatus = GetFitbitStatusesByIdRes;

export type FollowUpResponseSettings = FormQuestion["followUpResponseSettings"];

export type Form = GetFormsByIdRes;

export type FormInstance = GetFormInstancesByIdRes;

export type FormInstanceType = "Assessment" | "Note" | "Survey";

export type FormInstanceWithForm = FormInstance & {form: Form};

export type FormQuestion = Form["questions"][0];

export type GuidingHour = GetGuidingHoursByIdRes;

export type InsurancePlan = GetInsurancePlansByIdRes;

export type Message = GetMessagesByIdRes;

export type OnlineStatus = User["online"];

export type PatientOrFamilyMem = Pick<GetUsersByIdRes, (typeof patientAllowProps)[number]>;

export type PatientsOrFamilyMems = PatientOrFamilyMem[];

export type PreClinicUpdate = GetPreClinicUpdatesByIdRes;

export type PreferredPharmacy = User["preferredPharmacies"][0];

export type PtoCoverageConfig = GetPtoCoverageConfigsByIdRes;

export type RecurringScheduleItem = GetRecurringScheduleItemsByIdRes;

export type ReferralMethod = GetReferralMethodsByIdRes;

export type ReferralSource = GetReferralSourcesByIdRes;

export type ScheduleItem = GetScheduleItemsByIdRes;

export type ScheduleItemType = NonNullable<ScheduleItem["type"]>;

export type SmsMessaging = User["smsMessaging"];

export type StaffRole = StaffRoles;

export type TagType = (typeof addTagTypes)[number];

export type TaskData = Omit<ScheduleItemAttendee, "userId"> & {userId: User};

export type TodoItem = GetTodoByIdRes;

export type User = GetUsersByIdRes;

export type UserBio = GetUserBioByIdRes;

export type UserSession = GetUserSessionsByIdRes;

export type UserSettings = GetUserSettingsByIdRes;

export type UserStatus = GetUserStatusesByIdRes;

export type UserType = User["type"];

export type UserUpdate = GetUserUpdatesByIdRes;

export type Voicemail = GetVoicemailsByIdRes;

export type WorkflowMapping = GetWorkflowMappingsByIdRes;

export type WorkflowMappings = GetWorkflowMappingsRes;

export interface EngagementData {
  userId: string;
  primaryCaregiverName?: string;
  primaryCaregiverId?: string;
  userStatusId: string;
  patientMessages: number;
  patientAttended: number;
  patientAttendedNonClinical: number;
  patientOpenNotes: number;
  familyMessages: number;
  familyAttended: number;
  familyAttendedNonClinical: number;
  familyOpenNotes: number;
  engagementDays: number;
  engagementMonths: {start?: string; end?: string}[];
  selectedEngagementMonthStart: string;
  selectedEngagementMonthEnd: string;
}

export type UserFlags = Omit<User["featureFlags"], "_id">;

export interface ExplorerUsers extends User {
  status: UserStatus;
}

export interface ExplorerAlerts extends AlertInstance {
  referencedUser: User;
  todoItem: TodoItem;
  parentAlert: Alert;
}

export type Timezone =
  | "America/New_York"
  | "America/Chicago"
  | "America/Denver"
  | "America/Los_Angeles"
  | "America/Anchorage"
  | "Pacific/Honolulu"
  | "America/Phoenix";

type Primitive = string | number | boolean | bigint | symbol | undefined | null;
type NotAnObject = Primitive | any[] | ((...args: any[]) => any);

// This is necessary to avoid infinitely deep recursion. We shouldn't be nesting our data more than
// 3 layers deep anyway (hopefully).

// First layer
type DotNotation<T, Prefix extends string = ""> = T extends NotAnObject
  ? never
  : {
      [K in keyof T]-?: K extends string | number
        ? T[K] extends NotAnObject
          ? `${Prefix}${K}`
          : `${Prefix}${K}` | DotNotation2<T[K], `${Prefix}${K}.`>
        : never;
    }[keyof T];

// Second layer
type DotNotation2<T, Prefix extends string = ""> = T extends NotAnObject
  ? never
  : {
      [K in keyof T]-?: K extends string | number
        ? T[K] extends NotAnObject
          ? `${Prefix}${K}`
          : `${Prefix}${K}` | DotNotation3<T[K], `${Prefix}${K}.`>
        : never;
    }[keyof T];

// Third layer - recursion stops here by not calling further DotNotation
type DotNotation3<T, Prefix extends string = ""> = T extends NotAnObject
  ? never
  : {
      [K in keyof T]-?: K extends string | number ? `${Prefix}${K}` : never;
    }[keyof T];

export type UserDotNotation = DotNotation<User>;

export const AttendanceStatusMapping = {
  Attended: "Attended",
  Cancellation: "Cancellation",
  NoShow: "No-Show",
  Refused: "Refused",
  Reschedule: "Reschedule",
  // Unknown: "Unknown", Do not allow users to select this status
} as const;

export type AttendanceStatusValues =
  (typeof AttendanceStatusMapping)[keyof typeof AttendanceStatusMapping];

export const MissingAttendanceStatuses: AttendanceStatusValues[] = [
  "Cancellation",
  "No-Show",
  "Refused",
  "Reschedule",
];
