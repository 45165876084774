import {useGetUsersByIdQuery, usePatchUsersByIdMutation, UserSelectFieldOptions} from "@store";
import {isStaff} from "@utils";
import {Box, Button, SelectField, Text} from "ferns-ui";
import React, {useEffect, useState} from "react";

import {userSelectFieldOptions} from "../store/modelTypes";

interface UserSelectFieldProps {
  userId: string;
  field: string;
}

export const UserSelectField = ({
  userId,
  field,
}: UserSelectFieldProps): React.ReactElement | null => {
  const [updateUser, {isLoading}] = usePatchUsersByIdMutation();
  const {data: user} = useGetUsersByIdQuery(userId);
  const existingValue: string = (user as any)[field] || "";
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(existingValue);

  // When the user changes, refresh the current text with the user's values.
  // && avoid resetting value while editing.
  useEffect(() => {
    if (!isEditing) {
      setValue(existingValue);
    }
  }, [field, user, isEditing, existingValue]);

  if (!user || isStaff(user?.type)) {
    return null;
  }

  if (isEditing) {
    return (
      <Box>
        <SelectField
          options={[...userSelectFieldOptions[field as keyof UserSelectFieldOptions]] as any}
          value={value}
          onChange={(result): void => setValue(result)}
        />
        <Box direction="row" paddingY={2}>
          <Box marginRight={4}>
            <Button
              disabled={isLoading || !value}
              loading={isLoading}
              text="Save"
              onClick={async (): Promise<void> => {
                await updateUser({id: user._id, body: {[field]: value}});
                setIsEditing(false);
              }}
            />
          </Box>
          <Button
            text="Cancel"
            onClick={(): void => {
              setIsEditing(false);
              setValue(existingValue || ""); // Reset to existingValue or empty string
            }}
          />
        </Box>
      </Box>
    );
  } else {
    return (
      <Box>
        <Box paddingY={4}>
          <Text>{value}</Text>
        </Box>
        <Box direction="row" paddingY={2} width={180}>
          <Button
            iconName="pencil"
            text="Edit"
            onClick={(): void => {
              setIsEditing(true);
              setValue(existingValue);
            }}
          />
        </Box>
      </Box>
    );
  }
};
