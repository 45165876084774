import {
  baseWebsocketsUrl,
  getAuthToken,
  logSocket,
  useSelectCurrentUserId,
  useSocketConnection,
} from "@ferns-rtk";
import {
  flourishApi,
  TagType,
  useAppDispatch,
  useAppSelector,
  useSelectInternalChatConversationId,
  useSelectInternalChatIsFocused,
} from "@store";
import React, {createContext, useContext, useEffect} from "react";
import {Socket} from "socket.io-client";

import {useReadProfile} from "../hooks";
import {captureEvent, playSound, unloadSounds} from "../utils";

interface ISocketContext {
  socket: Socket | null;
}

// Create a context with a default value of null for the socket
export const SocketContext = createContext<ISocketContext>({socket: null});

// Custom hook to use the socket context
export const useSocket = (): ISocketContext => useContext(SocketContext);

interface SocketProviderProps {
  children: React.ReactElement;
}

// Provider Component
export const SocketProvider = ({children}: SocketProviderProps): React.ReactElement => {
  const selectedInternalChatConversation = useSelectInternalChatConversationId();
  const internalChatIsFocused = useSelectInternalChatIsFocused();
  const dispatch = useAppDispatch();
  const currentUserId = useSelectCurrentUserId();
  const apiState = useAppSelector((state) => state[flourishApi.reducerPath]);
  const profile = useReadProfile();

  const handleDisconnect = async (): Promise<void> => {
    await unloadSounds();
  };

  const {socket} = useSocketConnection({
    baseUrl: baseWebsocketsUrl,
    getAuthToken,
    shouldConnect: Boolean(currentUserId && profile?._id),
    captureEvent: (eventName: string, data: Record<string, any>) => {
      captureEvent(eventName, {
        ...data,
        userType: profile?.type ?? "unknown",
      });
    },
    onDisconnect: handleDisconnect,
  });

  // Set up Flourish-specific socket event listeners
  useEffect(() => {
    if (!socket) {
      return;
    }

    const onSocketEvent = (data: {
      collection: string;
      type:
        | "delete"
        | "invalidate"
        | "update"
        | "replace"
        | "insert"
        | "drop"
        | "dropDatabase"
        | "rename";
      updatedFields: Record<string, any>;
      _id: string;
    }): void => {
      const collection = data.collection;

      if (data.type === "insert") {
        // For inserts, we always invalidate as we want to fetch new data
        logSocket(profile, `Invalidating tag for insert: ${collection}`);
        dispatch(flourishApi.util.invalidateTags([collection as any]));
      } else if (data.type === "update") {
        // Conversationstatuses are a special case, we don't ever have them client side,
        // but they are added to conversations as a virtual field for unread. We only care about
        // our own conversation status.

        // Check if the document exists in the provided cache, which is all the tags for the
        // collection that are currently in the RTK query cache.
        const documentExistsInTags = (): boolean => {
          const provided = apiState.provided ?? ({} as Record<string, any>);

          const providedCollection = provided[collection];
          return Boolean(providedCollection?.[data._id]);
        };

        if (!documentExistsInTags()) {
          logSocket(
            profile,
            `Skipping invalidation for ${collection} - document ${data._id} is not in cache`
          );
          return;
        } else if (collection === "users") {
          logSocket(profile, `Invalidating conversations and alert instances for user update`);
          dispatch(flourishApi.util.invalidateTags([{type: collection, id: data._id}]));
        } else if (collection === "notifications") {
          // We send notifications updates over websockets, no need to invalidate anything.
        } else if (collection === "usersessions") {
          // Required so that the user's online status (dnd, last online,
          // etc) is updated in the UI across users across browser sessions
          logSocket(profile, `Invalidating user sessions for user session update`);
          dispatch(
            flourishApi.util.updateQueryData("getUserSessions", {page: 1}, (draft) => {
              draft.data = draft.data?.map((doc) =>
                doc._id === data._id ? Object.assign(doc, data.updatedFields) : doc
              );
            })
          );
        } else if (collection === "messages") {
          logSocket(profile, "Not invalidating messages since we handle them in the messagesEvent");
        } else {
          // For all other collections, invalidate the specific document
          logSocket(profile, `Invalidating document ${collection}/${data._id}`);
          dispatch(flourishApi.util.invalidateTags([{type: collection as any, id: data._id}]));
        }
      }
    };

    const onInvalidateTagEvent = async (data: {collection: TagType}): Promise<void> => {
      logSocket(profile, `invalidateTagEvent for ${data.collection}`);
      dispatch(flourishApi.util.invalidateTags([data.collection]));
    };

    // When notifications for a message are updated (e.g.
    // twilio status changes from pending to delivered) we need to update the notification status in
    // the RTK query cache.
    const onMessageNotificationEvent = async (data: {
      messageId: string;
      conversationId: string;
      notificationId: string;
      notificationEventData: Record<string, any>;
    }): Promise<void> => {
      logSocket(
        profile,
        `Notification event: ${data.messageId} ${data.notificationId} ${JSON.stringify(
          data.notificationEventData
        )}`
      );

      // Update the message notification status in the RTK query cache.
      // Note: this will only update the notification status for the first page of messages
      // Note: this will break if we change the initial message query for a conversation
      dispatch(
        flourishApi.util.updateQueryData(
          "getMessages",
          {conversationId: data.conversationId, page: 1},
          (draft) => {
            // Find all messages and update the notification status for the matching message
            draft.data = draft.data?.map((message) => {
              if (message._id === data.messageId) {
                return {
                  ...message,
                  notifications: message.notifications?.map((notification) =>
                    notification._id === data.notificationId
                      ? {...notification, ...data.notificationEventData}
                      : notification
                  ),
                };
              }
              return message;
            });
          }
        )
      );
    };

    // Attach Flourish-specific event listeners
    socket.on("changeEvent", onSocketEvent);
    socket.on(`invalidateTagEvent`, onInvalidateTagEvent);
    socket.on(`messageNotificationEvent`, onMessageNotificationEvent);

    // Cleanup function to remove event listeners
    return (): void => {
      socket.off("changeEvent", onSocketEvent);
      socket.off(`invalidateTagEvent`, onInvalidateTagEvent);
      socket.off(`messageNotificationEvent`, onMessageNotificationEvent);
    };
  }, [socket, dispatch, apiState, profile, currentUserId]);

  // Handle notification events separately based on internal chat state
  useEffect(() => {
    if (!socket || !currentUserId) return;

    const onNotificationEvent = async (data: {
      conversationId?: string;
      sound: string;
    }): Promise<void> => {
      if (
        data.conversationId &&
        data.conversationId === selectedInternalChatConversation &&
        internalChatIsFocused
      ) {
        // Don't play a sound if the internal chat is focused and the notification is for the
        // conversation that is selected
        return;
      }
      await playSound(data.sound);
    };

    socket.on(`notificationEvent${currentUserId}`, onNotificationEvent);

    // Cleanup to avoid multiple listeners
    return (): void => {
      socket.off(`notificationEvent${currentUserId}`, onNotificationEvent);
    };
  }, [socket, selectedInternalChatConversation, internalChatIsFocused, currentUserId]);

  return <SocketContext.Provider value={{socket}}>{children}</SocketContext.Provider>;
};
