import {useNavigation} from "@react-navigation/native";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {skipToken} from "@reduxjs/toolkit/query";
import {StaffStackParamList} from "@types";
import {Box, IconButton, Text} from "ferns-ui";
import {DateTime} from "luxon";
import React, {useCallback} from "react";

import {useGetCarePodsByIdQuery} from "../store/openApiSdk";

export const SchedulingSlotCell: React.FC<{
  cellData: {
    value: string;
    slot?: any;
    scheduleItemId?: string;
    textSize?: "sm" | "md" | "lg";
  };
}> = ({cellData}) => {
  const navigation = useNavigation<NativeStackScreenProps<StaffStackParamList>["navigation"]>();
  const {scheduleItemId, slot, value, textSize} = cellData ?? {};
  const {data: carePod} = useGetCarePodsByIdQuery(slot?.carePodId ?? skipToken);
  const staff = carePod?.careTeam?.Therapist;

  const addAppointment = useCallback(() => {
    navigation.navigate("CreateIntake", {
      slot: {
        ...slot,
        type: "Therapy - Patient Session",
        startDatetime: slot.datetime,
        endDatetime: DateTime.fromISO(slot.datetime).plus({minutes: 40}).toISO()!,
      },
      staffIds: [staff?._id ?? ""],
      userIds: [],
      cadence: slot?.hasEveryOtherWeekNextWeek ? "Every Other Week" : "Weekly",
    });
  }, [navigation, slot, staff]);

  if (!slot) {
    return null;
  }

  return (
    <Box direction="row" gap={4} width="100%">
      <IconButton
        accessibilityLabel={scheduleItemId ? "Edit Appointment" : "Add Appointment"}
        iconName={scheduleItemId ? "pencil" : "plus"}
        tooltipText={scheduleItemId ? "Edit Appointment" : "Add Appointment"}
        variant="secondary"
        onClick={() => {
          if (scheduleItemId) {
            navigation.navigate("CreateScheduleItem", {scheduleItemId});
          } else {
            addAppointment();
          }
        }}
      />
      <Text size={textSize}>{value}</Text>
    </Box>
  );
};
