import {Box, printDate} from "ferns-ui";
import get from "lodash/get";
import {DateTime} from "luxon";
import React from "react";

import {TextCell} from "./TextCell";

export const DateCell = ({
  model,
  field,
  displaySince = false,
}: {
  model?: any;
  field: string;
  displaySince?: boolean;
}): React.ReactElement => {
  const value = get(model, field);

  let since = "";
  if (displaySince && value) {
    since = `\n(${DateTime.fromISO(value).toRelative({style: "short"})})`;
  }

  return (
    <Box flex="grow" justifyContent="center" width="100%">
      <TextCell cellData={{value: value ? `${printDate(value)}${since}` : ""}} />
    </Box>
  );
};
